



























import Vue from 'vue';
import { OAuth2Service } from '../../libs/oauth2';
import { routeToErrorPage } from '../../libs/util';

export default Vue.extend({
  name: 'authenticationLogoutView',
  data() {
    return {
      isMounted: false,
      isLoading: false,

      metadata: {
        client_id: this.$route.query.client_id?.toString() || undefined,
        return_to: this.$route.query.returnTo?.toString() || undefined,
      },
    };
  },

  async mounted() {
    this.$set(this, 'isLoading', true);
    try {
      await OAuth2Service.logout({
        client_id: this.metadata.client_id,
        returnTo: this.metadata.return_to,
      });
    } catch (e) {
      routeToErrorPage();
      return;
    } finally {
      this.$set(this, 'isLoading', false);
    }

    // Redirect to return_to page
    if (this.metadata.return_to !== undefined) {
      window.location.assign(this.metadata.return_to);
      return;
    }

    this.$set(this, 'isMounted', true);
  },
});
